import React, { useState } from 'react'
import { useNavigate } from 'react-router'
import axios from 'axios'
import { API_AUTH_PATHS } from 'app-constants/paths'
import './AuthPage.scss'
import AsyncLocalStorage from '@createnextapp/async-local-storage'

const AuthPage = () => {
  const [password, updatePassword] = useState('')
  const [email, updateEmail] = useState('')
  const navigate = useNavigate()

  const handleAuth = (e) => {
    e.preventDefault()
    axios
      .post(API_AUTH_PATHS.sign_in, {
        email,
        password
      })
      .then(async ({ data, headers }) => {
        await AsyncLocalStorage.setItem(
          'user',
          JSON.stringify({
            'access-token': headers['access-token'],
            client: headers.client,
            uid: headers.uid,
            userType: data.user.userType
          })
        )
        console.log('user', data.user)
      })
      .catch((response) => {
        switch (response.status) {
          case 401:
            alert('Erreur authentification')
            break
          default:
            alert('Erreur')
            break
        }
      })
      .finally(() => {
        window.location.reload()

        return navigate('/', { replace: true })
      })
  }

  return (
    <div className="cv-auth-container">
      <div className="cv-auth-container-title">
        <h1>Connexion</h1>
      </div>
      <div className="ui input">
        <form className="ui form">
          <div className="field">
            <input
              type="text"
              onChange={({ target: { value } }) => updateEmail(value)}
              name="email"
              placeholder="email"
            />
          </div>
          <div className="field">
            <input
              type="password"
              onChange={({ target: { value } }) => updatePassword(value)}
              name="password"
              placeholder="password"
            />
          </div>
          <button className="ui button green" onClick={handleAuth}>
            Vers l'infini et au-delà !
          </button>
        </form>
      </div>
    </div>
  )
}

export default AuthPage
