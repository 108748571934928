import { createContext } from 'react'

export const ADMIN_USER = 'admin'
export const REGULAR_USER = 'regular'

const user = localStorage.user ? JSON.parse(localStorage.user) : {}

export const isAdmin = user?.userType === ADMIN_USER
export const isUser = user?.userType === REGULAR_USER
export const isAuthenticated = [ADMIN_USER, REGULAR_USER].includes(user?.userType)

export const UserContext = createContext({
  authenticated: false,
  user,
  isAdmin,
  isUser,
  isAuthenticated
})
