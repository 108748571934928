import React from 'react'
import axios from 'axios'
import { BASE_API_URL } from 'app-constants'
import { Button, Table, Modal } from 'semantic-ui-react'
import { isAdmin } from 'contexts/UserContext'
import { addFavorite } from 'api-clients/movies'

const MovieDetailsModal = ({ movie, open, onClose }) => {
  if (!movie) return null

  return (
    <Modal centered size="mini" open={open} onClose={onClose} style={{ top: 0 }}>
      <Modal.Header>Plus d'informations</Modal.Header>
      <Modal.Content>
        <Table basic="very" celled unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Original Title</Table.Cell>
              <Table.Cell>{movie.original_title}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Original Language</Table.Cell>
              <Table.Cell>{movie.original_languages}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Country</Table.Cell>
              <Table.Cell>{movie.country}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Released</Table.Cell>
              <Table.Cell>{movie.released}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Runtime</Table.Cell>
              <Table.Cell>{movie.runtime}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Metascore</Table.Cell>
              <Table.Cell>{movie.metascore}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Ratings</Table.Cell>
              <Table.Cell>
                <div>
                  {movie.ratings &&
                    movie.ratings.map((rating) => (
                      <div>
                        {rating.Source}: {rating.Value}
                      </div>
                    ))}
                </div>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Allocine</Table.Cell>
              <Table.Cell>
                <a target="_blank" rel="noopener noreferrer" href={movie.allocine_url}>
                  Lien
                </a>
              </Table.Cell>
            </Table.Row>
            {movie.imdb_id && (
              <Table.Row>
                <Table.Cell>Imdb</Table.Cell>
                <Table.Cell>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.imdb.com/title/${movie.imdb_id}`}
                  >
                    Lien
                  </a>
                </Table.Cell>
              </Table.Row>
            )}
            {isAdmin && (
              <Table.Row>
                <Table.Cell>Add favorite</Table.Cell>
                <Table.Cell>
                  <button
                    onClick={() => addFavorite(movie.id)}
                    className="ui button icon red"
                    disabled={movie.favorite}
                  >
                    <i className="heart icon" />
                  </button>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button
          positive
          icon="checkmark"
          labelPosition="right"
          content="Fermer"
          onClick={onClose}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default MovieDetailsModal
