import React from 'react'
import { Button } from 'semantic-ui-react'
import moment from 'moment'
import classnames from 'classnames'
import { isAdmin } from 'contexts/UserContext'
import './ShowTimesListItem.scss'

const normalizeTitle = (title) =>
  title
    .toLowerCase()
    .split(' ')
    .join('')
    .replace(/[.,/#!$%^&*;:{}=\-_`~()’']/g, '')

const formatTime = (showTime, runtime) => {
  const showStart = moment(showTime.showStart).utcOffset(0).format('dddd D HH:mm')
  const movieEnd = moment(showTime.showStart)
    .utcOffset(0)
    .add('minutes', parseInt(runtime, 0))
    .format('HH:mm')

  return showStart.includes(movieEnd) ? showStart : `${showStart} - ${movieEnd}`
}

const areTitlesDifferent = (original_title, title) =>
  normalizeTitle(original_title) !== normalizeTitle(title)

const ShowTimesListItem = ({
  showTime,
  showTime: {
    movie,
    movie: { title, original_title },
    cinema
  },
  onShowModal,
  onHideMovie
}) => {
  const imageURL = ['N/A', undefined, ''].includes(movie.poster_url)
    ? ['N/A', undefined, ''].includes(movie.alt_poster_url)
      ? ''
      : movie.alt_poster_url
    : movie.poster_url

  return (
    <tr
      key={showTime.id}
      className={classnames({
        positive: movie.favorite
      })}
    >
      <td>{formatTime(showTime, movie.runtime)}</td>
      <td className="movie-title-cell">
        <img src={imageURL} alt="" height="70px" /> {title}
        <i>
          {original_title && areTitlesDifferent(original_title, title) && ` (${original_title})`}
        </i>
      </td>
      <td>
        <Button icon="add" onClick={() => onShowModal(movie)} />
      </td>
      <td>{cinema.name}</td>
      {isAdmin && (
        <td>
          <Button icon="trash" color="red" basic onClick={() => onHideMovie(showTime)} />
        </td>
      )}
    </tr>
  )
}

export default ShowTimesListItem
