import React, { useReducer, useContext } from 'react'
import classNames from 'classnames'
import { CINEMA_PRESETS } from 'app-constants'
import Select from 'react-select'
import { isAdmin } from 'contexts/UserContext'
import { ShowTimesContext } from 'contexts/ShowTimesContext'

const START_TIME_OPTIONS = [
  { label: '20:00' },
  { label: '19:00' },
  { label: '18:00' },
  { label: '17:00' },
  { label: '14:00' },
  { label: '09:00' }
]

const FIELD_NAMES = {
  cinemas: 'selectedCinemas',
  movies: 'selectedMovies',
  showDates: 'selectedShowDates',
  cinemasPresets: 'selectedCinemasPresets',
  cards: 'selectedCards'
}

const reducer = (state, { field, values }) => {
  switch (field) {
    case FIELD_NAMES.cinemasPresets:
      const cinemasIds = values?.map(({ cinemaIds }) => cinemaIds)
      const selectedCinemas = cinemasIds?.length
        ? state.cinemas.filter(({ value }) => cinemasIds[0].includes(value))
        : []
      return { ...state, selectedCinemas, selectedCinemasPresets: values }
    default:
      return { ...state, [field]: values }
  }
}

const ShowTimesForm = ({ movies, cinemas, showDates, cards, selectedMovie }) => {
  const { getShowTimes, isSubmitting, setIsSubmitting } = useContext(ShowTimesContext)

  const [state, dispatch] = useReducer(reducer, {
    movies,
    cinemas,
    cards,
    showDates,
    showHidden: false,
    showFavorites: false,
    startTimes: START_TIME_OPTIONS,
    selectedMovies: [selectedMovie],
    selectedCinemas: [],
    selectedShowDates: [],
    selectedStartTime: null,
    cinemasPresets: CINEMA_PRESETS,
    selectedCinemasPresets: [],
    selectedCards: []
  })

  const handleSubmit = (event) => {
    setIsSubmitting(true)
    const {
      selectedMovies,
      selectedCards,
      selectedCinemas,
      selectedShowDates,
      selectedStartTime,
      showHidden,
      showFavorites
    } = state
    event.preventDefault()

    if (
      [selectedMovies, selectedCinemas, selectedShowDates, selectedCards].every(
        (options) => !options?.filter((option) => option).length
      ) &&
      !showFavorites
    ) {
      alert('Sélectionner au moins une option (film/jour/cinema)')
      setIsSubmitting(false)
    } else {
      getShowTimes({
        selectedMovies,
        selectedCards,
        selectedCinemas,
        selectedShowDates,
        selectedStartTime,
        showHidden,
        showFavorites
      })
    }
  }

  return (
    <form className="sixteen wide mobile twelve wide tablet ten wide computer column centered">
      <div className="ui vertically divided grid stackable">
        <div className={isAdmin ? 'four column row' : 'three column row'}>
          <div className="column">
            Films
            <div style={{ margin: '1rem 0' }}>
              <Select
                id="movies"
                options={state.movies}
                isMulti
                onChange={(values) => dispatch({ field: FIELD_NAMES.movies, values })}
                value={state.selectedMovies}
                closeMenuOnSelect
              />
            </div>
          </div>
          <div className="column">
            Cinémas
            <div style={{ margin: '1rem 0' }}>
              <Select
                id="cinemas"
                options={state.cinemas}
                isMulti
                onChange={(values) => dispatch({ field: FIELD_NAMES.cinemas, values })}
                value={state.selectedCinemas}
              />
            </div>
          </div>

          {isAdmin && (
            <div className="column">
              Préselection de cinémas
              <div style={{ margin: '1rem 0' }}>
                <Select
                  id="cinemasPresets"
                  options={state.cinemasPresets}
                  isMulti
                  cinemas={state.cinemas}
                  onChange={(values) => dispatch({ field: FIELD_NAMES.cinemasPresets, values })}
                  value={state.selectedCinemasPresets}
                />
              </div>
            </div>
          )}
          <div className="column">
            Cartes
            <div style={{ margin: '1rem 0' }}>
              <Select
                id="cards"
                options={state.cards}
                isMulti
                onChange={(values) => dispatch({ field: FIELD_NAMES.cards, values })}
                value={state.selectedCards}
              />
            </div>
          </div>
        </div>
        <div className="three column row">
          <div className="column">
            Jour...
            <div style={{ margin: '1rem 0' }}>
              <Select
                id="showDates"
                options={state.showDates}
                isMulti={true}
                onChange={(values) => dispatch({ field: 'selectedShowDates', values })}
                value={state.selectedShowDates}
              />
            </div>
          </div>
          <div className="column">
            Début après...
            <div style={{ margin: '1rem 0' }}>
              <Select
                id="startTime"
                options={state.startTimes}
                isMulti={false}
                onChange={(values) => dispatch({ field: 'selectedStartTime', values })}
                value={state.selectedStartTime}
              />
            </div>
          </div>
          <div className="column">
            <div>
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  name="showHidden"
                  checked={state.showHidden}
                  onChange={() => dispatch({ field: 'showHidden', values: !state.showHidden })}
                />
                <label htmlFor="showDeleted">Show 'deleted' movies</label>
              </div>
            </div>
            <div>
              <div className="ui checkbox">
                <input
                  type="checkbox"
                  name="showFavorites"
                  checked={state.showFavorites}
                  onChange={() =>
                    dispatch({
                      field: 'showFavorites',
                      values: !state.showFavorites
                    })
                  }
                />
                <label htmlFor="showDeleted">Show favorites</label>
              </div>
            </div>
            <div>
              <button
                className={classNames('ui button primary', {
                  loading: isSubmitting
                })}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default ShowTimesForm
