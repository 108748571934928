import React, { PureComponent } from 'react'
import axios from 'axios'
import { BASE_API_URL } from 'app-constants'
import ShowTimesListItem from 'pages/ShowTimesPage/ShowTimesListItem'
import MovieDetailsModal from 'pages/ShowTimesPage/MovieDetailsModal'
import { isAdmin } from 'contexts/UserContext'

class ShowTimesList extends PureComponent {
  state = {
    modalOpened: false,
    modalMovie: null,
    isLoading: true,
    hiddenIds: []
  }

  onHideMovie = ({ movie: { id: movie_id } }) => {
    if (!window.confirm('hide?')) return
    const { hiddenIds } = this.state
    axios.post(`${BASE_API_URL}/hide_movie_for_user`, {
      movie_id,
      control_action: 'hide_movie'
    })
    return this.setState({ hiddenIds: [...hiddenIds, movie_id] })
  }

  onShowModal = (movie) => this.setState({ modalMovie: movie, modalOpened: true })

  onCloseModal = () => this.setState({ modalOpened: false })

  activeMovie = () => this.modalMovie

  render() {
    const { hiddenIds, modalMovie, modalOpened } = this.state
    const { showTimes } = this.props

    if (showTimes.length === 0) return null
    const displayedShowTimes = showTimes?.filter(
      (showTime) => !hiddenIds.includes(showTime.movie.id)
    )

    return (
      <div className="ui container">
        <table className="ui celled table unstackable">
          <thead>
            <tr>
              <th width="160px">Horaire</th>
              <th>Titre</th>
              <th width="10px">Plus d'infos</th>
              <th width="140px">Cinéma</th>
              {isAdmin && <th width="10px">Cacher</th>}
            </tr>
          </thead>
          <tbody>
            {showTimes &&
              displayedShowTimes.map((showTime) => (
                <ShowTimesListItem
                  key={showTime.id}
                  showTime={showTime}
                  onShowModal={this.onShowModal}
                  onHideMovie={this.onHideMovie}
                />
              ))}
          </tbody>
        </table>
        {modalMovie && modalOpened && (
          <MovieDetailsModal movie={modalMovie} open onClose={this.onCloseModal} />
        )}
      </div>
    )
  }
}

export default ShowTimesList
