import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { Button, Modal, Icon } from 'semantic-ui-react'
import { BASE_API_URL } from 'app-constants'
import axios from 'axios'
import moment from 'moment'

const ModalContent = (props) => {
  const { showTimes } = props
  const movie = showTimes[0].movie
  return (
    <div className="scrolling content">
      <table className="ui celled table">
        <tbody>
          {showTimes.map((showTime) => (
            <tr key={showTime.id}>
              <td>{showTime.cinema.name}</td>
              <td>{moment(showTime.showStart).utcOffset(0).format('dddd Do HH:mm')}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <a href={movie.allocineUrl} target="_blank" rel="noopener noreferrer">
          Allocine
        </a>
      </div>
      {movie.imdb_id && (
        <div>
          <a
            href={`https://www.imdb.com/title/${movie.imdb_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Imdb
          </a>
        </div>
      )}
      <Link
        to={{
          pathname: '/',
          state: { selectedMovie: { value: movie.id, label: movie.title } }
        }}
        className="item"
      >
        Horaires
      </Link>
    </div>
  )
}

class MovieShowTimesModal extends PureComponent {
  state = { showTimes: null }

  handleOpen = () => {
    axios
      .get(`${BASE_API_URL}/show_times.json`, {
        params: {
          q: {
            movie_id_eq: this.props.movie_id,
            show_time_show_start_gteq: new Date()
          }
        },
        headers: JSON.parse(localStorage.user)
      })
      .then(({ data: { showTimes } }) => {
        this.setState({ showTimes })
      })
      .catch((e) => console.log(e))
  }

  render() {
    return (
      <Modal
        className="scrolling"
        trigger={
          <Button onClick={this.handleOpen}>
            <Icon name="clock outline" />
          </Button>
        }
        header={`${this.props.movie_title} - ${this.props.original_title}`}
        size="small"
        content={this.state.showTimes && <ModalContent showTimes={this.state.showTimes} />}
        actions={[{ key: 'ok', content: 'Ok', positive: true }]}
      />
    )
  }
}

export default MovieShowTimesModal
