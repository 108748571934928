import { useQuery } from 'react-query'
import axios from 'api-clients/axios'
import { BASE_API_URL } from 'app-constants'

export const useShowTimesFormOptions = () =>
  useQuery(
    'form_options',
    async () =>
      await axios.get(`${BASE_API_URL}/form_options.json`).then(({ data }) => ({
        ...data,
        movies: data.movies.map(({ id, title }) => ({
          value: id,
          label: title
        })),
        showDates: data.showDates.map(({ rawDate, formattedDate }) => ({
          value: rawDate,
          label: formattedDate
        })),
        cinemas: data.cinemas.map(({ id, name }) => ({
          value: id,
          label: name
        })),
        cards: data.cards.map(({ id, name }) => ({
          value: id,
          label: name
        }))
      }))
  )
