// process.env.NODE_ENV === 'production' ? 'https://api.cineavolonte.date/api' : 'https://api.cineavolonte.date/api'
export const BASE_API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://api.cineavolonte.date/api'
    : 'http://localhost:3001/api'

export const CINEMA_PRESETS = [
  {
    value: 1,
    label: 'Quartier Latin',
    cinemaIds: [11, 12, 15, 23, 25, 30, 31, 32, 33, 44, 47, 48, 49, 50, 51, 57, 62]
  },
  {
    value: 2,
    label: 'Home',
    cinemaIds: [16, 21, 27, 35, 39, 40, 46, 54, 55]
  },
  {
    value: 3,
    label: 'Lyon',
    cinemaIds: [
      112, 111, 110, 109, 108, 107, 106, 105, 104, 103, 102, 101, 100, 99, 98, 97, 96, 95, 94, 93,
      92, 91
    ]
  },
  {
    value: 3,
    label: 'Lyon Home',
    cinemaIds: [96, 94, 91, 92, 93, 95, 102]
  }
]
