export const BASE_URL =
  process.env.NODE_ENV === 'production' ? 'https://api.cineavolonte.date' : 'http://localhost:3001'

export const API_AUTH_PATHS = {
  sign_in: `${BASE_URL}/auth/sign_in`,
  sign_out: `${BASE_URL}/auth/sign_out`,
  validate_token: `${BASE_URL}/auth/validate_token`
}

export const APP_PATHS = {
  root: '/',
  movies: '/movies',
  missing_movies: '/missing_movies',
  auth: '/auth',
  recap: '/recap',
  profile: '/profile'
}
