import { useQuery } from 'react-query'
import axios from 'axios'
import { BASE_API_URL } from 'app-constants'

export const useMissingMovies = () =>
  useQuery('missing_movies', async () => {
    const { data } = await axios.get(`${BASE_API_URL}/missing_movies/index_new_method.json`)
    return data
  })

export const useMissingMovieSuggestions = (movie_id) =>
  useQuery(['missing_movie_suggestions', movie_id], async () => {
    if (!movie_id) return

    const { data } = await axios.get(`${BASE_API_URL}/missing_movies/get_suggestions/${movie_id}`)
    return data
  })
