import React from 'react'
import axios from 'api-clients/axios'
import { API_AUTH_PATHS, APP_PATHS } from 'app-constants/paths'

const ProfilePage = () => {
  // const { isSuccess } = useQuery(
  //   'validate_token',
  //   async () => {
  //     const { uid, client, ...rest } = JSON.parse(localStorage.user)
  //     const data = await axios.get(API_AUTH_PATHS.validate_token, {
  //       params: {
  //         uid,
  //         client,
  //         'access-token': rest['access-token']
  //       }
  //     })
  //     return data
  //   },
  //   { enabled: false }
  // )

  const handleSignOut = () => {
    try {
      axios.delete(API_AUTH_PATHS.sign_out)
    } finally {
      localStorage.removeItem('user')
      window.location.pathname = APP_PATHS.auth
    }
  }

  return (
    <div>
      <h1>Mon Profil</h1>
      <button className="ui button red" onClick={handleSignOut}>
        Déconnexion
      </button>
      {false && (
        <>
          <h3>Mes films cachés</h3>
          <h3>Mes préselections de cinémas</h3>
        </>
      )}
    </div>
  )
}

export default ProfilePage
