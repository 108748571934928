import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import axios from 'axios'
import { BASE_API_URL } from 'app-constants'
import { useMissingMovies } from 'api-clients/missing-movies'
import { isAdmin } from 'contexts/UserContext'
import './MissingMoviesPage.scss'
import orderBy from 'lodash/orderBy'

const MissingMoviesNewPage = () => {
  const { data, isFetching } = useMissingMovies()
  const [movies, setMovies] = useState([])
  const [selectedMovie, setSelectedMovie] = useState(undefined)
  const [subjectMovie, setSubjectMovie] = useState(undefined)
  const [suggestedMovies, setSuggestedMovies] = useState(undefined)
  const [tmdbId, setTmdbId] = useState(undefined)

  const onSelectMovie = (new_id) =>
    isAdmin &&
    axios
      .post(`${BASE_API_URL}/missing_movies/${subjectMovie.id}`, {
        new_id
      })
      .then(() => {
        setMovies(movies.filter(({ id }) => id !== subjectMovie.id))
        setSelectedMovie(undefined)
        setSubjectMovie(undefined)
        setSuggestedMovies(undefined)
        setTmdbId('')
      })

  const sortByCount = (key) => setMovies(orderBy(movies, [key], ['desc']))

  useEffect(() => {
    if (!isFetching) setMovies(data?.movies)
  }, [isFetching, data?.movies])

  useEffect(() => {
    selectedMovie &&
      axios
        .get(`${BASE_API_URL}/missing_movies/get_suggestions/${selectedMovie.id}`)
        .then(({ data: { movie, suggestions, year } }) => {
          setSubjectMovie({ ...movie, year })
          setSuggestedMovies(suggestions)
        })
  }, [selectedMovie])

  const handleSkipMovie = () => {
    if (window.confirm(`skip? ${selectedMovie.id}`)) {
      axios
        .post(`${BASE_API_URL}/missing_movies/${subjectMovie.id}`, {
          skip: true
        })
        .then(() => {
          setMovies(movies.filter(({ id }) => id !== subjectMovie.id))
          setSelectedMovie(undefined)
          setSubjectMovie(undefined)
          setSuggestedMovies(undefined)
          setTmdbId(undefined)
        })
    }
  }

  return (
    <div className="movies-page">
      <div className="movies-table">
        <table className="ui table">
          <thead>
            <tr>
              <td>Titre</td>
              <td onClick={() => sortByCount('upcoming_show_times_count')}>Séances à venir</td>
              <td onClick={() => sortByCount('show_times_count')}>Séances</td>
            </tr>
          </thead>
          <tbody>
            {movies?.map((movie) => (
              <tr key={movie.id}>
                <td onClick={() => setSelectedMovie(movie)}>{movie.title}</td>
                <td>
                  {movie.upcoming_show_times_count > 0 ? movie.upcoming_show_times_count : null}
                </td>
                <td>{movie.show_times_count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="movies-suggestions">
        <div className="sticky-box">
          <div>
            <h1>{selectedMovie && selectedMovie.title}</h1>
            <a href={subjectMovie?.url} className="href" target="_blank" rel="noopener noreferrer">
              Allocine
              {subjectMovie?.year ? ` (${subjectMovie?.year})` : null}
            </a>
          </div>
          <div className="ui small image">
            {subjectMovie?.allocine_image_url ? (
              <img src={subjectMovie?.allocine_image_url} alt="allocine_image_url" />
            ) : null}
          </div>
          <div>
            <input
              type="text"
              placeholder="tmdb_id"
              value={tmdbId}
              onChange={({ target: { value } }) => setTmdbId(value)}
            />
            <a
              href={`https://www.themoviedb.org/search?query=${selectedMovie?.original_title}`}
              target="_blank"
              rel="noopener noreferrer"
              className="href"
            >
              TMDB
            </a>
            <div
              className={classNames('ui button', { disabled: !tmdbId })}
              onClick={() => onSelectMovie(tmdbId)}
            >
              Enter
            </div>
            {selectedMovie && (
              <div className={classNames('ui button red')} onClick={() => handleSkipMovie()}>
                Skip
              </div>
            )}
          </div>
          <div className="ui cards">
            {suggestedMovies?.map((suggestedMovie) => (
              <div key={suggestedMovie.id} className="ui card">
                <div className="image">
                  <img src={suggestedMovie.poster_path} alt="" />
                </div>
                <div className="content">
                  <a
                    className="header"
                    href={suggestedMovie.tmdb_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {suggestedMovie.title} ({suggestedMovie.original_title})
                  </a>
                  <div className="meta">
                    <span className="date">{suggestedMovie.release_date}</span>
                  </div>
                </div>
                <div
                  className="ui bottom attached button"
                  onClick={() => onSelectMovie(suggestedMovie.id)}
                >
                  <i className="add icon"></i>
                  Selectionner
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MissingMoviesNewPage
