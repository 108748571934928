import React from 'react'
import { createRoot } from 'react-dom/client'
import App from 'App'
import { QueryClient, QueryClientProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'

import { BrowserRouter } from 'react-router-dom'
import registerServiceWorker from './registerServiceWorker'

const queryClient = new QueryClient()
const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <App />
      {/* {process.env.NODE_ENV === "development" && <ReactQueryDevtools initialIsOpen={false} />} */}
    </QueryClientProvider>
  </BrowserRouter>
)

registerServiceWorker()
