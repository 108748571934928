import React, { useContext } from 'react'
import moment from 'moment'
import 'moment/locale/fr'
import { Route, Link, Navigate, Routes } from 'react-router-dom'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { APP_PATHS } from 'app-constants/paths'

import ShowTimesPage from 'pages/ShowTimesPage'
import MoviesPage from 'pages/MoviesPage'
import MissingMoviesPage from 'pages/MissingMoviesPage'
import AuthPage from 'pages/AuthPage'
import RecapPage from 'pages/RecapPage'
import { UserContext } from 'contexts/UserContext'
import ProfilePage from 'pages/ProfilePage'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.user ? (
        <Component {...props} />
      ) : (
        <Navigate to={{ pathname: APP_PATHS.auth, state: { from: props.location } }} />
      )
    }
  />
)

const App = ({
  router: {
    location: { pathname }
  }
}) => {
  moment.locale('fr')
  const { isAuthenticated } = useContext(UserContext)
  console.log({ isAuthenticated })
  return (
    <UserContext.Provider value={{}}>
      {isAuthenticated && (
        <>
          <div className="ui item menu three">
            <Link to={APP_PATHS.root} className="item">
              Séances
            </Link>
            <Link to={APP_PATHS.movies} className="item">
              Films
            </Link>
            <Link to={APP_PATHS.profile} className="item">
              Profile
            </Link>
          </div>
        </>
      )}
      {!isAuthenticated && pathname != APP_PATHS.auth && <Navigate to={APP_PATHS.auth} replace />}
      {isAuthenticated && pathname == APP_PATHS.auth && <Navigate to={APP_PATHS.root} replace />}
      <Routes>
        <Route exact path={APP_PATHS.root} element={<ShowTimesPage />} />
        <Route path={APP_PATHS.recap} element={<RecapPage />} />
        <Route path={APP_PATHS.auth} element={<AuthPage />} />
        <Route path={APP_PATHS.movies} element={<MoviesPage />} />
        <Route path={APP_PATHS.profile} element={<ProfilePage />} />
        <Route path={APP_PATHS.missing_movies} element={<MissingMoviesPage />} />
      </Routes>
    </UserContext.Provider>
  )
}

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation()
    let navigate = useNavigate()
    let params = useParams()

    return <Component {...props} router={{ location, navigate, params }} />
  }

  return ComponentWithRouterProp
}
export default withRouter(App)
