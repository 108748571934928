import React, { Component } from 'react'
import axios from 'api-clients/axios'
import ReactTable from 'react-table'
// import 'react-table/react-table.css'
import { BASE_API_URL } from 'app-constants'
import { Link } from 'react-router-dom'
import MovieShowTimesModal from 'pages/MoviesPage/MovieShowTimesModal'
import { isAdmin } from 'contexts/UserContext'
import { APP_PATHS } from 'app-constants/paths'
import { addFavorite } from 'api-clients/movies'
import { Icon } from 'semantic-ui-react'

const MAX_SHOWTIMES_NUMBER = 100

const jsonSafeParse = (object) => JSON.parse(JSON.stringify(object))

const parseMoviesList = (moviesList) =>
  moviesList?.map((movie) => ({
    ...movie,
    tmdb_details: jsonSafeParse(movie.tmdb_details),
    omdb_details: jsonSafeParse(movie.omdb_details),
    ratings: jsonSafeParse(movie.ratings),
    allocine_details: jsonSafeParse(movie.allocine_details)
  }))

const getMoviesList = (params = {}) =>
  axios
    .get(`${BASE_API_URL}/movies.json`, {
      params: { ...params }
    })
    .then(({ data }) => parseMoviesList(data.movies))

const parseRating = (rater, { ratings }) =>
  ratings
    ? ratings.map((rating) => (rating.Source === rater ? parseFloat(rating.Value) : ''))
    : undefined

class MoviesPage extends Component {
  state = { movies: [] }

  async componentDidMount() {
    const movies = await getMoviesList()
    this.setState({ movies })
  }

  onAddFavorite = (movie_id) =>
    isAdmin &&
    axios
      .post(
        `${BASE_API_URL}/movies/${movie_id}`,
        {
          movie_id,
          control_action: 'add_favorite'
        },
        { headers: JSON.parse(localStorage.user) }
      )
      .then(() => (this.state.movies.find(({ id }) => id === movie_id).favorite = true))

  setDate = (date) => this.setState({ date })

  restrictMoviesToDate = async () => {
    const movies = await getMoviesList({ date: this.state.date })
    this.setState({ movies })
  }

  render() {
    const columns = [
      {
        Header: 'title',
        accessor: 'title',
        minWidth: 150,
        Cell: ({ original: { id, title } }) => (
          <Link
            to={{
              pathname: '/',
              state: { selectedMovie: { value: id, label: title } }
            }}
            className="item"
          >
            {title}
          </Link>
        )
      },
      {
        Header: 'Original title',
        accessor: 'tmdb_details.original_title',
        maxWidth: 150
      },
      { Header: 'Séances', accessor: 'show_times_count', maxWidth: 50 },
      {
        Header: 'Horaires',
        accessor: 'show_times_modal',
        maxWidth: 80,
        Cell: ({
          original: { id: movie_id, title: movie_title, original_title, show_times_count }
        }) =>
          show_times_count <= MAX_SHOWTIMES_NUMBER && (
            <MovieShowTimesModal
              movie_id={movie_id}
              movie_title={movie_title}
              original_title={original_title}
            />
          )
      },
      {
        id: 'imdb',
        Header: 'IMDB',
        maxWith: 10,
        accessor: (data) => parseRating('Internet Movie Database', data)
      },
      {
        id: 'rotten',
        Header: 'RT',
        maxWith: 10,
        accessor: (data) => parseRating('Rotten Tomatoes', data)
      },
      {
        id: 'metacritic',
        Header: 'Metacric',
        maxWith: 10,
        accessor: (data) => parseRating('Metacritic', data)
      },
      {
        id: 'tmdb',
        Header: 'TheMovieDB',
        maxWith: 10,
        accessor: (data) =>
          [0, ''].includes(data.tmdb_details.vote_average)
            ? undefined
            : data.tmdb_details.vote_average
      },
      {
        id: 'add_favorite',
        Header: 'Fav',
        maxWith: 20,
        Cell: ({ original: { id, favorite } }) =>
          isAdmin &&
          !favorite && (
            <button onClick={() => this.onAddFavorite(id)} className="ui button icon red">
              <i className="heart icon" />
            </button>
          ),
        accessor: ({ favorite }) => favorite
      }
    ]

    const { movies } = this.state

    return (
      <div>
        {isAdmin ? (
          <div className="ui two column centered grid">
            <div className="column">
              <Link to={APP_PATHS.recap}>
                <button className="ui button">recap</button>
              </Link>
            </div>
            <div className="column">
              <Link to={APP_PATHS.missing_movies}>
                <button className="ui button">Missing movies</button>
              </Link>
            </div>
          </div>
        ) : null}
        {movies ? (
          <>
            <div className="ui cards">
              {movies.map((movie) => (
                <div className="card" key={movie.id}>
                  <img className="image" src={movie.omdb_details.Poster} alt={movie.title} />
                  <div className="content">
                    <div className="header">{movie.title}</div>
                    <div className="meta">{movie.tmdb_details.original_title}</div>
                  </div>
                  <div className="extra content">
                    <span className="right floated star" onClick={() => addFavorite(movie.id)}>
                      <Icon color={movie.favorite ? 'yellow' : 'grey'} name="star" />
                      {!movie.favorite && 'Ajouter aux favoris'}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <div>
              Only list movies shown on
              <input type="date" onChange={(event) => this.setDate(event.target.value)} />
              <button className="ui button" onClick={() => this.restrictMoviesToDate()}>
                Go
              </button>
              <button
                className="ui button"
                onClick={() =>
                  this.setState({ date: undefined }, () => this.restrictMoviesToDate())
                }
              >
                Reset
              </button>
            </div>

            {/* <ReactTable
              data={movies}
              columns={columns}
              defaultSorted={[
                { id: 'rotten', desc: true },
                { id: 'imdb', desc: true }
              ]}
              defaultPageSize={-1}
            /> */}
          </>
        ) : null}
      </div>
    )
  }
}

export default MoviesPage
