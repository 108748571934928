import React, { useState } from 'react'
import axios from 'axios'
import { BASE_API_URL } from 'app-constants'
import { useShowTimesFormOptions } from 'api-clients/showtimes'
import { ShowTimesContext } from 'contexts/ShowTimesContext'
import ShowTimesList from 'pages/ShowTimesPage/ShowTimesList'
import ShowTimesForm from 'pages/ShowTimesPage/ShowTimesForm'

const getValues = (collection) => collection?.map((values) => values?.value)

const ShowTimesPage = (props) => {
  const [showTimes, setShowTimes] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data: formOptions, isLoading } = useShowTimesFormOptions()

  const getShowTimes = async ({
    selectedMovies,
    selectedCards,
    selectedCinemas,
    selectedShowDates,
    selectedStartTime,
    showHidden,
    showFavorites
  }) =>
    await axios
      .get(`${BASE_API_URL}/show_times.json`, {
        params: {
          q: {
            movie_id_in: getValues(selectedMovies),
            cinema_id_in: getValues(selectedCinemas),
            show_time_show_start_date_in: getValues(selectedShowDates),
            show_time_show_start_hour_gteq: selectedStartTime?.label,
            movie_hidden_not_eq: showHidden ? undefined : true,
            movie_favorite_eq: showFavorites ? true : undefined,
            card_ids_in: getValues(selectedCards)
          }
        },
        headers: JSON.parse(localStorage.user)
      })
      .then(({ data }) => setShowTimes(data.showTimes))
      .catch((e) => console.log(e))
      .finally(() => setIsSubmitting(false))

  return (
    <div className="App">
      {!isLoading && (
        <ShowTimesContext.Provider value={{ getShowTimes, isSubmitting, setIsSubmitting }}>
          <div>
            <div className="ui grid">
              <ShowTimesForm
                movies={formOptions.movies}
                cinemas={formOptions.cinemas}
                showDates={formOptions.showDates}
                cards={formOptions.cards}
                selectedMovie={props.location?.state?.selectedMovie}
              />
            </div>
            {showTimes?.length ? (
              <>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <h5>
                    {showTimes.length} séances,{' '}
                    {new Set(showTimes.map(({ movie }) => movie.id)).size} films
                  </h5>
                </div>
                <ShowTimesList showTimes={showTimes} />
              </>
            ) : null}
          </div>
        </ShowTimesContext.Provider>
      )}
    </div>
  )
}

export default ShowTimesPage
