import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'
import { BASE_API_URL } from 'app-constants'

const RecapPage = () => {
  const [data, setData] = useState({
    show_times_created_today: 0,
    movies_created_today: 0,
    movies_with_tmdb_details: 0,
    movies_with_omdb_details: 0,
    total_showtimes: 0,
    show_times_creations: []
  })

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get(`${BASE_API_URL}/show_times/scrap_recap`, {
        headers: JSON.parse(localStorage.user)
      })
      const { show_times_creations, ...rest } = result.data
      setData({
        ...rest,
        show_times_creations: show_times_creations.sort(([a, b], [a2, b2]) =>
          moment(a).isAfter(moment(a2)) ? 1 : -1
        )
      })
    }
    fetchData()
  }, [])

  return (
    <div>
      <h1>recap</h1>
      <p>show_times_created_today: {data.show_times_created_today}</p>
      <p>movies_created_today: {data.movies_created_today}</p>
      <p>movies_with_tmdb_details: {data.movies_with_tmdb_details}</p>
      <p>movies_with_omdb_details: {data.movies_with_omdb_details}</p>
      <p>total_showtimes: {data.total_showtimes}</p>
      <div>
        <ul>
          {data.show_times_creations.map(([date, count], index) => (
            <li key={index}>
              {date} : {count}
            </li>
          ))}
        </ul>
      </div>
      <iframe
        src="https://metabase.pe-projects.eu/public/dashboard/372bdca7-1acc-4eab-b080-0d9b7a2fb6c8"
        frameBorder="0"
        width="800"
        height="600"
        allowtransparency
        title="metabase recap"
      ></iframe>
    </div>
  )
}

export default RecapPage
